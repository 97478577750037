/*Includes TableCSS, TableRCSS*/

#Container {
    margin-right: 10px;
    margin-left: 10px;
    display: flex;
    margin-top: 80px;
}

#clients {
    margin-top: 2px;
    margin-right: 1%;
    width: 98%;
    display: block;
}

#clients2 {
    overflow-y: hidden;
    margin-right: 0.7%;
    width: 98%;
}

select {
    width: 150px;
    height: 25px;

}

#loadClientsFilter {
    width: 150px;
    height: 25px;
    border-radius: 0;
    margin-right: 10px;
}

#loadDillerFilter {
    width: 150px;
    height: 25px;
    border-radius: 0;
    margin-right: 10px;
}

#search {
    height: 20px;
    color: black;
}

option {
    color: black;
    min-width: 0;
    display: block;
}

#addButton {
    margin-top: 1.2%;
    text-align: center;
    border: 2px solid #024457;
    border-radius: 100%;
    min-width: 40px;
    min-height: 40px;
    font-size: 30px;
    color: #176a7e;
    background-color: #80808047;

}


#deleteButton {
    border: 1px solid #024457;
    border-radius: 100%;
    width: 26px;
    height: 26px;
    cursor: pointer;
    text-align: center;
}

.sales {
    font-size: 30px;
}

.salesPercent {
    margin-top: 20px;
    font-size: 25px;
}

#deleteButton:hover {
    opacity: 0.8;
}

.buttonClient {
    margin: 1em 0;
    display: inline-block;
    line-height: 3em;
    min-height: 80%;
    padding-left: 1%;
    padding-right: 1%;
    width: 100px;
    min-width: 80px;
    font-size: 13px;
    min-font-size: 14px;
    color: #0f5665;
    background: #FFFFFF;
    border-radius: 0.5em;
    background-clip: padding-box;
    border-color: #D9E4E6 !important
}

.phoneIma {
    width: 20px;
    margin-top: -100px;
    height: 20px;
    background-color: rgba(255, 255, 255, 0);
    margin-left: 5px;

}

#hour {
    font-weight: bold;
}

#editClient {

    border-radius: 50%;
    width: 45px;
    height: 45px;
}

.waitingForClient {
    color: darkblue;

}

.waitingForMeeting {
    color: darkblue;

}

.waitingForDate {
    color: darkblue;

}

.unRelevant {
    color: dimgrey;

}

.endTreat {
    color: green;

}

label {
    display: block;
    color: #7d7d7d;
}

span {
    color: #0f5665;
}

.dealerName {
    color: black;
}

.logoGreen {
    width: 50px;
    height: 50px;
}

.buttonT {
    font-size: 1em;
    cursor: pointer;
    align-self: center;
}

.sortImg {
    object-fit: contain;
    max-height: min-content;
    height: 15px;

}

.buttonContainerTable {
    display: flex;
    justify-content: center;
    width: 30px;
    height: 30px;
}

.sortContainer {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-top: 4%;
    padding-bottom: 4%;
    height: 100%;
    justify-content: center;
}

.tableChild {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;

}

@media only screen and (max-width: 1200px) {
    #Container {
        width: 95%;
        display: flex;
        margin-top: 40px;
        justify-content: flex-start;
        align-items: center;
    }

    .sales {
        font-size: 18px;
    }

    .salesPercent {
        font-size: 15px;
    }

    .sortContainer {

        padding-top: 0.5%;
        padding-bottom: 0.5%;

    }

    .sortImg {
        max-height: border-box;

        height: 10px;

    }

    select, #loadClientsFilter, #search, #loadDillerFilter {
        width: 23%;
        height: 22px;
        margin: 0;
        font-size: 11px;


    }

    .buttonT {
        font-size: 1.4em;
        align-self: center;
    }

    #search {
        height: 16px;
    }

    .logoGreen {
        width: 20px;
        height: 20px;
    }

    .buttonClient {

        width: fit-content;
        min-width: 100%;
        font-size: 12px;
        min-font-size: 13px;

    }

    #addButton {

        position: absolute;
    }


    #clients {
        margin-top: 20px;
        margin-right: 2%;
        display: block;
        width: 96%;
    }

    tbody {
        /*
        height: fit-content;
        */
        display: inline-block;
        width: 100%;
        /*  overflow-y: scroll;*/
        overflow-x: scroll;

    }


    .phoneIma {

        margin-right: 5px;


    }


}
