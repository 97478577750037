.inputContainer {

    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-width: fit-content;
    height: 30px;
    max-width: 400px;
}

.dealerP {
    width: 98%;
}

.dealerPop {
    display: block;
    overflow: scroll;
    flex-direction: column;
    height: 100%;

}

.tableCell {
    width: 22%;
    min-width: 100px;
}

.tableDiller {
    background-color: #0f566529;
    height: 90%;
    font-size: 20px !important;
}

.imageDillers {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.buttonDiller {
    border-radius: 100%;
    width: 70px;
    height: 70px;
    color: #176a7e;
    border: 1px solid #176a7e;
    margin-bottom: 8%;
    font-size: 18px;
    background-color: #eeeeee;
    font-family: Arial, sans-serif;


}

.editContainer {
    display: flex;
    justify-content: center;
}

.editIcon {
    cursor: pointer;
    width: 26px;
    height: 26px;
    padding-right: 7px;
}

.editIcon:hover {
    opacity: 0.8;
}

.DpoUpChild {
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    padding: 0 2em;
    width: 50%;
    height: 50%;
    background-color: white;
    margin-right: 25%;
    margin-top: 10%;
    border: 1px solid #167F92;
}

.formDiller {
    margin-top: 20px;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    width: 100%;
    align-items: center;
    font-family: Arial, sans-serif;
    height: 40%;
}

.textForm {
    color: #024457;
    font-size: 20px;

}

.textDiller {
    color: #024457;
    font-size: 18px;
}

.preview_container {

}

@media only screen and (max-width: 1050px) {
    .inputContainer {

        width: 100%;
    }

    .dealerPop {
        overflow-y: scroll;
        flex-direction: column;
        height: 100%;

    }

    .dealerP {
        display: block
    }

    .textForm, .textDiller {
        font-size: 17px;

    }

    .textDiller {
        max-width: 150px;
        height: 15px;
    }

    .buttonDiller {
        width: 55px;
        height: 55px;
        margin-bottom: 8%;
        font-size: 15px;


    }

    .formDiller {
        margin-top: 10px;
        height: 70%;
        max-width: 300px;
        align-self: center;
    }

    .DpoUpChild {
        padding-right: 5%;
        padding-left: 5%;
        width: 80%;
        margin-right: 5%;
        margin-left: 5%;
        margin-top: 30%;

    }
}