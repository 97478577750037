
.DealerHolder {
    height: 300px;
    background-color: white;
    border: 1px solid black;
    border-radius: 10px;
    width: 300px;
    right: 100px;
    z-index: 1000;
    top: 50px;
    display: flex;
    flex-direction: column;
    position: absolute;
}

.titleProfile {
    text-align: center;
    font-size: 2em;
    margin-right: 5px;
    color: #024457;
    margin-top: 2px;
}

.textProfile {
    margin-top: 14px;
    color: #024457;
    margin-right: 5px;
    font-weight: lighter;
    text-align: center;
    font-size: 1.1em;
}

.editPro {
    width: 20px;
    cursor: pointer;
    text-align: center;
    height: 20px;
}

.editPro:hover {
    opacity: 0.8;
}

.passContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    width: 80%;
    height: 40%;
}

.passInvalid {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    width: 80%;
    height: 40%;
    opacity: 0.2;
}

.inputProfile {
    margin-top: 7px;
    width: 100%;
    height: 18px;

}

.profileButton {
    margin-top: 7px;
    width: 90%;
    align-self: center;
    height: 18px;

}

.inputMail {
    width: 90%;
    align-self: center;
    margin-top: 10px;
    text-align: center;
}

.deleteButton {
    border: 1px solid #024457;
    border-radius: 100%;
    width: 26px;
    height: 26px;
    font-size: 1em;
    margin-right: 90%;

    margin-top: 5px;
    cursor: pointer;
    text-align: center;
}

.deleteButton:hover {
    opacity: 0.8;
}
@media only screen and (max-width: 1250px) {
    .DealerProfileContainer{
        width: 100vw;
        background-color: rgba(255,255,255,0.5);
        z-index: 1000;
        height: 60vh;
        display: flex;
        justify-content: center;
        right: 0;
        position: absolute;
    }
    .DealerHolder {
        right: unset;
    }

}