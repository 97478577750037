
.graphCell {
    width: 10%;
}

.graphTable {
    margin-top: 15%;
    width: 100%;
    height: 5%;
}

.popUpDivG {
    align-items: center;
    width: 100%;
    min-height: 20%;
    height: fit-content;
    left: 0;
    top: 0;
    z-index: 0;
    position: absolute;

}

.graphTitle {
    font-size: 30px;
    font-family: Arial, Arial, sans-serif;
    color: #02657d;
    width: 100%;
    margin: 1px;
    text-align: center;
}

.GpoUpChild {
    direction: rtl;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: fit-content;
    justify-content: flex-end;
    margin-top: 50px;
    margin-left: 100px;

}

.graphContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    width: 500px;
}

@media only screen and (max-width: 1000px) {
    .graphContainer {
        margin-top: 50px;
        width: 100%;


    }

    .GpoUpChild {
        justify-content: center;
    }
}
