.programContainer {
    margin-top: 100px;
    display: flex;
    direction:ltr;
    flex-direction: column;
}

.title {
    font-size: 40px;
    width: 100%;
    text-align: center;
    color: #0f5665;
    margin-top: 40px;
    margin-bottom: 40px;
}

.SearchContainer {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
}

.searchP {
    background-color: white;
    border: none;
    width: 100%;

}

.tableP {
    border: none;
}

.inputP {
    width: 96%;
    margin-bottom: 10px;
    background-color: white;
    direction: ltr;
}

.noSearch {
    vertical-align: bottom;
    background-color: white;
}

.noSearchInput {
    background-color: #136b7e;
    height: 30px;
    width: 100%;

}
#amountSearch{
}
.amount{
    font-size: 20px;
    width: 100%;
    direction: ltr;
    margin-left: 10px;
    align-self: end;
    color: #024457;
}
.load{
    font-size: 20px;
    color: #024457;
}
.added{

    width: 96%;
    margin-bottom: 10px;
    direction: ltr;
    color: #D9E4E6;
    background-color: rgba(35, 117, 48, 0.7);
}
.dataR{
    display: flex;
    justify-content: center;
    background-color: rgba(255,255,255,0.9);
    position: absolute;
    flex-direction: row;
    width:100vw;
    height:100vh;

}
.searchIP {
    background-color: #136b7e;
    display: flex;
    font-size: 15px;
    flex-direction: column;
    height: 40px;
    justify-content: center;

}