/*Includes popUpCSS, CalendarPopCSS*/


.dialog {
    width: fit-content;
    margin-right: 98%;
    margin-top: 2%;

}

#popUpComment {
    font-size: 20px;
    color: #02657d;
    width: 80%;
    text-align: right;
    margin-top: -2%;
    margin-right: 2%;
    margin-left: 2%;
}

.popUpName {
    font-size: 40px;
    font-family: Arial, Arial, sans-serif;
    color: #02657d;
    width: 100%;
    margin: 1px;
    text-align: center;
}

#statusHolder {
    display: flex;
    margin-bottom: 2px;
    margin-top: 10px;
    align-items: center;
}

#statusTitle {
    color: black;
    margin-top: 0;
    width: 40%;
    min-width: max-content;
}

#changeStatus {
    margin: -0.5% 0.5% 10% 1%;
    border: none;
    width: 70%;
    height: 20px;
    max-width: 100px;
    font-size: 15px;
    background-color: rgba(0, 0, 0, 0);

}

.boldp {
    font-weight: bold;
}

#datec2 {
    margin-top: 1%;
    margin-right: 10px;
    color: darkblue;
}

#datec:hover {
    cursor: pointer;
}

#datec {
    margin-top: 1%;
    margin-right: 4%;
    color: darkblue;
}

#TablePopUp {
    border-radius: 20px;
    border: #656d6e;
    margin-top: -1%;
}

.dateHourPop {
    width: 19%;
}


#EditPop {
    width: 15%;

}


#deleteButton {
    border: 1px solid #024457;
    border-radius: 100%;
    margin-top: 1%;
    text-align: center;
    color: #024457;
    width: 26px;
    height: 26px;
    font-size: 15px;


}

#editButton {
    margin-right: 6%;
    margin-left: 2%;
}

.exitButton:after {
    content: 'X'; /* ANSI X letter */
}

.addContainer {
    margin-top: 5%;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    width: 100%;
}

#addArea {
    resize: none;
    width: 90%;
    border: 1px solid #a5adae;
    margin-right: 10px;
    height: 70px;
    margin-top: -4%;

}

#commentArea {
    width: 100%;
    height: 50px;
    color: #024457;
}

#submitButton {
    border-radius: 100%;
    height: 50px;
    width: 50px;

    color: #024457;
    border: 1px solid #167F92;

}

.calendarPop {
    width: 30%;
    height: 40%;
    margin-top: 12%;
    margin-right: 40%;
    background-color: rgba(255, 255, 255, 0.9);


}

.exitButtonCalendar {
    font: 100% arial;
    color: darkblue;
    text-decoration: none;
    border: none;
    background-color: rgba(170, 170, 170, 0);
    margin-right: 92%;
    margin-top: 2%;
    height: 30px;
    width: 30px;
    font-weight: bold;
}

.exitButtonCalendar:after {
    content: 'X'; /* ANSI X letter */
}

#changeStatus {
    margin: -0.1% 1% 3% 1%;
    width: 60%;
    border: none;
    font-size: 15px;


}

#daysC {

    font: 100% arial;
    color: darkblue;
    text-decoration: none;
    border: none;
    background-color: rgba(170, 170, 170, 0);
    height: 30px;
    width: 150px;
    font-weight: bold;
    margin-top: -6%;
    margin-right: 1%;
}

#time {
    width: 40%;
    height: 0.3%;
    margin-bottom: 10px;
    direction: ltr;
}

.endTreat {
    color: green;

}

.calendarCl {
    width: 80%;
    margin: -4% 10% 10% 5%;
    height: 80%;

}

.addC {
    font-size: 18px;
    color: #024457;
    font-family: Arial, sans-serif;
}

#saveC {
    color: darkslategrey;
    border: 1px solid darkslategrey;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    margin-top: 2%;
    margin-right: 43%;
}

.tablePop {
    flex: 1;
    overflow-y: scroll;
    overflow-x: scroll;
    color: #024457;
}

.PpoUpChild {
    min-height: 500px;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    padding: 0 2em;
    width: 50%;
    height: 50%;
    background-color: white;
    margin-right: 25%;
    margin-top: 10%;
    border: 1px solid #167F92;
}

.dateHolder {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 40%;
}
.formPopUp{
    display: flex;
    width: fit-content;
    justify-content: space-between;
    min-width: 70px;
    max-width: 90px;
}
.dillersSelect{
    margin-top: 15px;
}
@media only screen and (max-width: 1050px) {

    #TablePopUp {
        display: block;

    }
    .popUpName {
        font-size: 20px;
        margin-top: 20px;

    }
    .dillersSelect {
        margin-top: 0;
        margin-right: 15px;
        align-self: center;
    }

    .formPopUp{
        margin-right: 10px;
    }
    .commentsDiv{
        display: flex;

    }
    .preview_container{
        width: 50%!important;

    }
    .preview_container svg{
        width:20px!important;
    }
    .PpoUpChild {
        padding-right: 2.5%;
        padding-left: 2.5%;
        min-height: 350px;
        width: 90%;
        height:50vh;
        padding-bottom: 50px;
        background-color: white;
        margin-right: 2.5%;
        margin-left: 2.5%;
        margin-top: 20%;
        border: 1px solid #167F92;
    }
    .dateHolder {
        flex-direction: column;
        width: 50%;
    }
    .addContainer {
        margin-bottom: 4px;
    }

    #popUpComment {
        font-size: 15px;
        width: fit-content;
        margin: 0;
    }

    #statusTitle {
        font-size: 15px;

    }
    .dateHourPop{
        font-size: 12px;
    }
    .addC {
        font-size: 15px;
        color: #024457;
        margin-right: 0;
        font-family: Arial, sans-serif;
    }

    #time {
        width: 70%;
        margin-right: 0;
        margin-top: 2%;
        direction: ltr;
    }

    #editButton {
        margin-top: 10px;
    }

    #changeStatus, #datec, #datec2 {
        font-size: 14px;


    }

    .calendarPop {
        width: 90%;
        height: fit-content;
        margin-top: 50%;
        padding-bottom: 20px;
        margin-right: 5%;

    }


    #datec2 {
        width: 100%;
        text-align: center;
        margin: 0;
    }

    #datec {
        width: 100%;
        margin: 0;
        text-align: center;

    }


    .dialog {
        margin-right: 90%;
        margin-top: 4%;

    }

    #addArea {
        margin-top: 0;
        height: 100%;
    }


    #deleteButton {
        margin-top: 0;
        width: 20px;
        height: 20px;
        font-size: 12px;
        padding-right: 5px;
        padding-left: 5px;

    }
}
