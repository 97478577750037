/*Includes basics css, mutualCSS, MainCSS, AddClientWindow, ClientEditorWindowCSS,PaginationCSS*/

table {
    background-color: #FFF;
    color: #024457;
    border: 1px solid #167F92;
    text-align: center;
    width: 100%;
    border-radius: 10px;
    margin: 1em 0;
}

textarea {
    font-size: 13px;
    padding-top: 2%;
    text-align: center;
}

h1 {
    width: 100%;
    text-align: center;
    font-size: 50px;
    margin-top: 50px;
    mso-ansi-font-weight: bold;
    font-weight: normal;
    color: #424242;

}

body {
    padding: 0 0.5em;
    font-family: Arial, sans-serif;
    color: #f2f2f2;
    direction: rtl;
}


tr, td {
    width: 8%;
    text-align: center;
}

label {
    display: block;
    color: #7d7d7d;
}

th {
    color: #FFFFFF;
    width: 8%;
    text-align: center;
    font-size: 16px;
    border: 1px solid #FFFFFF;
    border-bottom: 1px solid black;
    background-color: #136b7e;
    border-bottom: 1px solid black;

    -ms-scrollbar-highlight-color: #656d6e;


}

#addButton {
    text-align: center;
    border: 2px solid #024457;
    border-radius: 100%;
    width: 40px;
    font-size: 30px;
    color: #176a7e;
    min-height: 40px;
    min-width: 40px;
}

td {
    min-width: max-content;
    word-wrap: break-word;
    border: 1px solid #D9E4E6;
    padding: 0.5% 0.5% 0.5% 0.5%;

}


#titleStart {
    color: #024457;
    font-size: 50px;
}

.EnterWindow {
    color: #024457;
    direction: ltr;
    height: 25%;
    width: 15%;
    align-self: center;


}

#userNameConteiner {
    display: flex;
    margin-left: 15%;
    margin-top: 5%;
    align-items: center;
    width: 100%;
    justify-content: center;
    height: fit-content;

}

#userNameTitle {
    color: #024457;
    font-size: 25px;
    text-align: center;
    width: 25%;
}


#passwordContainer {
    display: flex;
    width: 100%;
    align-items: center;
    height: fit-content;
    justify-content: center;
}

#passwordTitle {
    font-size: 25px;
    width: 25%;
    text-align: center;
    direction: rtl;
}

#submitStart {
    border-radius: 100%;
    height: 100px;
    width: 100px;
    cursor: pointer;
    font-size: 20px;
    margin-top: 1%;
    color: #024457;
    border: 1px solid #024457;
}

#clientsTotal {
    color: black;
}

.popUpDiv {
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: rgba(170, 170, 170, 0.6);
    position: fixed;
}

tbody {
    display: table;
    width: 100%;
}

.deleteMessage {
    left: 50%;
    right: 42%;
    top: 33%;
    bottom: 10%;
    width: 320px;
    height: 190px;
    min-width: 320px;
    min-height: 190px;
    background-color: #FFFFFF;
    border: 1px solid #656d6e;
    color: #176a7e;
    text-align: center;
    position: fixed;
    padding-top: 1%;
    display: flex;
    flex-direction: column;
}

#passDelClient {
    margin-top: 5%;
    margin-bottom: -100%;
    width: 50%;
    margin-right: 30%;
    border: 1px solid lightgray;
    height: 30%;
}

#passDelClientTitle {
    text-align: right;
    margin-top: -6%;
    margin-bottom: 10%;
    margin-right: 4%;
    font-size: 15px;
}

#buttonContainer {
    width: 250px;
    min-width: 250px;
    display: flex;
    margin-right: 30px;
    margin-bottom: 20px;
}

.buttons {
    border-radius: 100%;
    width: 50px;
    height: 50px;
    color: #176a7e;
    border: 2px solid #dae3e3;
    margin-right: 10px;

}

.noCareR {
    background-color: rgba(255, 0, 0, 0.2);

}

.call {
    background-color: rgba(255, 255, 102, 0.5);
}

.noCare {
    color: red;
}

.notAnswerRow {
    background-color: rgba(255, 152, 0, 0.35);
}

.coldLeadRow {
    background-color: rgba(31, 151, 141, 0.2);
}

.dillerClient {
    background-color: rgba(0, 192, 0, 0.28);
}

.lessRelevantRow, .outOfCountry {
    background-color: rgba(216, 211, 216, 0.5);;

}

.meetingRow {
    background-color: rgba(153, 196, 239, 0.5);;

}

.waitingRow {
    background-color: rgba(183, 245, 183, 0.5);
}

.Care {
    color: darkgreen;
}

.Sale {
    color: darkgreen;

}


.exitButton {
    font: 15px arial;
    right: 5px;
    color: #176a7e;
    text-decoration: none;
    border: none;
    background-color: white;
    font-weight: bold;
    top: 5px;
}

.exitButton:after {
    content: 'X'; /* ANSI X letter */
}

#buttonExit {
    padding-bottom: 4.9%;
    margin-right: 97%;
    color: #FFFFFF;
    font-size: 22px;
    width: 30px;
    background-color: rgba(0, 0, 0, 0);
    height: 30px;
}

#editTable {
    height: 100%;
    margin-top: -5%;
    margin-bottom: 1%;
    border: none;

}

#clientEditor {
    border-radius: 10px;
    background-color: #FFFFFF;
    margin-top: 10%;
    margin-right: 15%;
    margin-left: 5% !important;
    width: 70%;
    height: 25%;
}

#commentPop {
    width: 20%;

}

.dillerMark {
    cursor: pointer;
    margin-top: 1px;
    max-width: 60px;
    object-fit: contain;
}

.dealerProfile {
    cursor: pointer;
    object-fit: contain;
    z-index: 3;
    border-radius: 60px;
    max-width: 60px;
    align-self: center;
}

.dealerProfile:hover {
    opacity: 0.8;
}

.popUpDiv {
    align-items: center;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 1000;
    top: 0;
    background-color: rgba(170, 170, 170, 0.6);
    position: fixed;

}

.textEditorClient {
    width: 100%;
    height: 100%;
    max-height: 100px;
    resize: none;
    alignment: center;
    font-size: 15px;
    text-align: center;
    color: #024457;


}

#buttonAdd {
    margin-bottom: 8%;
    margin-top: -0.5%;
    height: 50px;
    width: 50px;
    margin-right: 60%;
    font-size: 14px;
    border: 1px solid #024457;

}

.radioAddClient {
    width: 20px;
    height: 20px;

}

.diller {
    cursor: pointer;
    object-fit: contain;
    border-radius: 60px;
    max-width: 60px;
    align-self: center;
}

.buttonContain {
    display: flex;
    width: 90%;
    padding-left: 5%;
    padding-right: 5%;
    flex-direction: row;

}

.AddCon {
    display: flex;
    margin-right: 10px;
    flex-direction: column;
}

.pagination {
    width: 40%;
    align-items: center;
    display: inline-block;
    color: #02657d;
    list-style: none;
    margin-right: 18%;
    text-decoration: none;
    direction: ltr;
}

.pagination li {
    mso-bidi-font-weight: bold;
    color: black;
    float: left;
    padding: 8px 14px;
    font-weight: bold;


}

.pagination a {
    color: #2a2a2a;
}

.pagination li.active {
    background-color: #167F92;
    color: #FFFFFF;
}

#editPop {
    width: 25%;
}

.normal {
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: -50px;
}

.formPop {
    width: 30%;
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.iconH {
    display: flex;
    flex-direction: column;
    height: fit-content;
}

.tableCon {
    margin-top: 0;
}

@media only screen and (max-width: 1050px) {
    .tableCon {
        margin-top: 250px;

    }

    .iconH {
        align-items: flex-start;
        margin-right: 15px;
    }

    #buttonContainer {
        width: 95%;
        min-width: 0;
        margin-right: 2.5%;
    }

    .normal {
        margin: 0;
    }

    #userNameTitle {
        color: #024457;
        font-size: 16px;
        min-width: 30%;
    }

    .buttonContain {
        margin-top: 15px;

    }


    .formPop {
        justify-content: flex-start;
        width: 50%;


    }

    .dillerMark, .diller, .dealerProfile {
        max-width: 30px;
    }

    #submitStart {
        height: 70px;
        width: 70px;
        align-self: center;
        font-size: 12px;
    }

    #titleStart {
        font-size: 30px;
    }

    #passwordTitle {
        font-size: 16px;
        width: 30%;
        direction: rtl;
    }

    #userNameConteiner, #passwordContainer {
        margin-left: 0;
        width: 100%;
        height: fit-content;
        margin-right: 0;
        min-height: 75px;
        margin-top: 0;

    }

    .radioAddClient {
        width: 15px;
        height: 15px;

    }

    .deleteMessage {
        width: 90%;
        right: 5%;
        left: 5%;
        min-width: 200px;
    }

    .EnterWindow {
        height: 16px;
        width: 40%;
    }

    .textEditorClient {
        width: 95%;
        font-size: 13px;


    }

    .selAdd {
        width: 100px;
        max-width: 60%;
    }

    .greenTitle {
        font-size: 20px;
        margin-top: 0;
    }

    #clientEditor {
        margin-right: 2.5%;
        margin-left: 2.5% !important;
        width: 95%;
        height: fit-content;
        margin-top: 50%;
    }

    .pagination {
        width: 100%;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        font-size: 12px;
    }

    #buttonAdd {
        margin-bottom: 15px;
        margin-top: 10px;
        height: 40px;
        margin-right: 48%;
        width: 40px;
        font-size: 12px;
        border: 1px solid #024457;

    }

    #clients td, #clients tr, #clients th {
        min-width: 60px;

    }

    th, tr, td {

        width: fit-content;
        font-size: 13px;
        text-align: center;
        padding-right: 5px;
        padding-left: 5px;
        min-width: 120px;
    }

    td {
        height: 50px;
    }

    #editTable {
        width: 98%;
        margin-top: 0;
        margin-right: 1%;
        display: block;
        margin-bottom: 0;

    }

    #addButton {
        text-align: center;
        padding: 0;
        display: flex;
        justify-content: center;
        width: 40px;
        height: 40px;
        font-size: unset;
        color: #176a7e;
        min-height: 0;
        background-color: #e5e5e5;
        min-width: 0;
    }

    body {
        padding: 0 0;
        scroll-snap-type: none

    }

    #buttonExit {
        margin-right: 90%;
        color: #176a7e;

    }

    tbody {
        display: inline-block;
    }

}

/*/////////////////////////////*/



