body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*Includes TableCSS, TableRCSS*/

#Container {
    margin-right: 10px;
    margin-left: 10px;
    display: flex;
    margin-top: 80px;
}

#clients {
    margin-top: 2px;
    margin-right: 1%;
    width: 98%;
    display: block;
}

#clients2 {
    overflow-y: hidden;
    margin-right: 0.7%;
    width: 98%;
}

select {
    width: 150px;
    height: 25px;

}

#loadClientsFilter {
    width: 150px;
    height: 25px;
    border-radius: 0;
    margin-right: 10px;
}

#loadDillerFilter {
    width: 150px;
    height: 25px;
    border-radius: 0;
    margin-right: 10px;
}

#search {
    height: 20px;
    color: black;
}

option {
    color: black;
    min-width: 0;
    display: block;
}

#addButton {
    margin-top: 1.2%;
    text-align: center;
    border: 2px solid #024457;
    border-radius: 100%;
    min-width: 40px;
    min-height: 40px;
    font-size: 30px;
    color: #176a7e;
    background-color: #80808047;

}


#deleteButton {
    border: 1px solid #024457;
    border-radius: 100%;
    width: 26px;
    height: 26px;
    cursor: pointer;
    text-align: center;
}

.sales {
    font-size: 30px;
}

.salesPercent {
    margin-top: 20px;
    font-size: 25px;
}

#deleteButton:hover {
    opacity: 0.8;
}

.buttonClient {
    margin: 1em 0;
    display: inline-block;
    line-height: 3em;
    min-height: 80%;
    padding-left: 1%;
    padding-right: 1%;
    width: 100px;
    min-width: 80px;
    font-size: 13px;
    min-font-size: 14px;
    color: #0f5665;
    background: #FFFFFF;
    border-radius: 0.5em;
    background-clip: padding-box;
    border-color: #D9E4E6 !important
}

.phoneIma {
    width: 20px;
    margin-top: -100px;
    height: 20px;
    background-color: rgba(255, 255, 255, 0);
    margin-left: 5px;

}

#hour {
    font-weight: bold;
}

#editClient {

    border-radius: 50%;
    width: 45px;
    height: 45px;
}

.waitingForClient {
    color: darkblue;

}

.waitingForMeeting {
    color: darkblue;

}

.waitingForDate {
    color: darkblue;

}

.unRelevant {
    color: dimgrey;

}

.endTreat {
    color: green;

}

label {
    display: block;
    color: #7d7d7d;
}

span {
    color: #0f5665;
}

.dealerName {
    color: black;
}

.logoGreen {
    width: 50px;
    height: 50px;
}

.buttonT {
    font-size: 1em;
    cursor: pointer;
    align-self: center;
}

.sortImg {
    object-fit: contain;
    max-height: -webkit-min-content;
    max-height: -moz-min-content;
    max-height: min-content;
    height: 15px;

}

.buttonContainerTable {
    display: flex;
    justify-content: center;
    width: 30px;
    height: 30px;
}

.sortContainer {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-top: 4%;
    padding-bottom: 4%;
    height: 100%;
    justify-content: center;
}

.tableChild {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;

}

@media only screen and (max-width: 1200px) {
    #Container {
        width: 95%;
        display: flex;
        margin-top: 40px;
        justify-content: flex-start;
        align-items: center;
    }

    .sales {
        font-size: 18px;
    }

    .salesPercent {
        font-size: 15px;
    }

    .sortContainer {

        padding-top: 0.5%;
        padding-bottom: 0.5%;

    }

    .sortImg {
        max-height: border-box;

        height: 10px;

    }

    select, #loadClientsFilter, #search, #loadDillerFilter {
        width: 23%;
        height: 22px;
        margin: 0;
        font-size: 11px;


    }

    .buttonT {
        font-size: 1.4em;
        align-self: center;
    }

    #search {
        height: 16px;
    }

    .logoGreen {
        width: 20px;
        height: 20px;
    }

    .buttonClient {

        width: -webkit-fit-content;

        width: -moz-fit-content;

        width: fit-content;
        min-width: 100%;
        font-size: 12px;
        min-font-size: 13px;

    }

    #addButton {

        position: absolute;
    }


    #clients {
        margin-top: 20px;
        margin-right: 2%;
        display: block;
        width: 96%;
    }

    tbody {
        /*
        height: fit-content;
        */
        display: inline-block;
        width: 100%;
        /*  overflow-y: scroll;*/
        overflow-x: scroll;

    }


    .phoneIma {

        margin-right: 5px;


    }


}

/*Includes popUpCSS, CalendarPopCSS*/


.dialog {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-right: 98%;
    margin-top: 2%;

}

#popUpComment {
    font-size: 20px;
    color: #02657d;
    width: 80%;
    text-align: right;
    margin-top: -2%;
    margin-right: 2%;
    margin-left: 2%;
}

.popUpName {
    font-size: 40px;
    font-family: Arial, Arial, sans-serif;
    color: #02657d;
    width: 100%;
    margin: 1px;
    text-align: center;
}

#statusHolder {
    display: flex;
    margin-bottom: 2px;
    margin-top: 10px;
    align-items: center;
}

#statusTitle {
    color: black;
    margin-top: 0;
    width: 40%;
    min-width: -webkit-max-content;
    min-width: -moz-max-content;
    min-width: max-content;
}

#changeStatus {
    margin: -0.5% 0.5% 10% 1%;
    border: none;
    width: 70%;
    height: 20px;
    max-width: 100px;
    font-size: 15px;
    background-color: rgba(0, 0, 0, 0);

}

.boldp {
    font-weight: bold;
}

#datec2 {
    margin-top: 1%;
    margin-right: 10px;
    color: darkblue;
}

#datec:hover {
    cursor: pointer;
}

#datec {
    margin-top: 1%;
    margin-right: 4%;
    color: darkblue;
}

#TablePopUp {
    border-radius: 20px;
    border: #656d6e;
    margin-top: -1%;
}

.dateHourPop {
    width: 19%;
}


#EditPop {
    width: 15%;

}


#deleteButton {
    border: 1px solid #024457;
    border-radius: 100%;
    margin-top: 1%;
    text-align: center;
    color: #024457;
    width: 26px;
    height: 26px;
    font-size: 15px;


}

#editButton {
    margin-right: 6%;
    margin-left: 2%;
}

.exitButton:after {
    content: 'X'; /* ANSI X letter */
}

.addContainer {
    margin-top: 5%;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    width: 100%;
}

#addArea {
    resize: none;
    width: 90%;
    border: 1px solid #a5adae;
    margin-right: 10px;
    height: 70px;
    margin-top: -4%;

}

#commentArea {
    width: 100%;
    height: 50px;
    color: #024457;
}

#submitButton {
    border-radius: 100%;
    height: 50px;
    width: 50px;

    color: #024457;
    border: 1px solid #167F92;

}

.calendarPop {
    width: 30%;
    height: 40%;
    margin-top: 12%;
    margin-right: 40%;
    background-color: rgba(255, 255, 255, 0.9);


}

.exitButtonCalendar {
    font: 100% arial;
    color: darkblue;
    text-decoration: none;
    border: none;
    background-color: rgba(170, 170, 170, 0);
    margin-right: 92%;
    margin-top: 2%;
    height: 30px;
    width: 30px;
    font-weight: bold;
}

.exitButtonCalendar:after {
    content: 'X'; /* ANSI X letter */
}

#changeStatus {
    margin: -0.1% 1% 3% 1%;
    width: 60%;
    border: none;
    font-size: 15px;


}

#daysC {

    font: 100% arial;
    color: darkblue;
    text-decoration: none;
    border: none;
    background-color: rgba(170, 170, 170, 0);
    height: 30px;
    width: 150px;
    font-weight: bold;
    margin-top: -6%;
    margin-right: 1%;
}

#time {
    width: 40%;
    height: 0.3%;
    margin-bottom: 10px;
    direction: ltr;
}

.endTreat {
    color: green;

}

.calendarCl {
    width: 80%;
    margin: -4% 10% 10% 5%;
    height: 80%;

}

.addC {
    font-size: 18px;
    color: #024457;
    font-family: Arial, sans-serif;
}

#saveC {
    color: darkslategrey;
    border: 1px solid darkslategrey;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    margin-top: 2%;
    margin-right: 43%;
}

.tablePop {
    flex: 1 1;
    overflow-y: scroll;
    overflow-x: scroll;
    color: #024457;
}

.PpoUpChild {
    min-height: 500px;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    padding: 0 2em;
    width: 50%;
    height: 50%;
    background-color: white;
    margin-right: 25%;
    margin-top: 10%;
    border: 1px solid #167F92;
}

.dateHolder {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 40%;
}
.formPopUp{
    display: flex;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    justify-content: space-between;
    min-width: 70px;
    max-width: 90px;
}
.dillersSelect{
    margin-top: 15px;
}
@media only screen and (max-width: 1050px) {

    #TablePopUp {
        display: block;

    }
    .popUpName {
        font-size: 20px;
        margin-top: 20px;

    }
    .dillersSelect {
        margin-top: 0;
        margin-right: 15px;
        align-self: center;
    }

    .formPopUp{
        margin-right: 10px;
    }
    .commentsDiv{
        display: flex;

    }
    .preview_container{
        width: 50%!important;

    }
    .preview_container svg{
        width:20px!important;
    }
    .PpoUpChild {
        padding-right: 2.5%;
        padding-left: 2.5%;
        min-height: 350px;
        width: 90%;
        height:50vh;
        padding-bottom: 50px;
        background-color: white;
        margin-right: 2.5%;
        margin-left: 2.5%;
        margin-top: 20%;
        border: 1px solid #167F92;
    }
    .dateHolder {
        flex-direction: column;
        width: 50%;
    }
    .addContainer {
        margin-bottom: 4px;
    }

    #popUpComment {
        font-size: 15px;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        margin: 0;
    }

    #statusTitle {
        font-size: 15px;

    }
    .dateHourPop{
        font-size: 12px;
    }
    .addC {
        font-size: 15px;
        color: #024457;
        margin-right: 0;
        font-family: Arial, sans-serif;
    }

    #time {
        width: 70%;
        margin-right: 0;
        margin-top: 2%;
        direction: ltr;
    }

    #editButton {
        margin-top: 10px;
    }

    #changeStatus, #datec, #datec2 {
        font-size: 14px;


    }

    .calendarPop {
        width: 90%;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        margin-top: 50%;
        padding-bottom: 20px;
        margin-right: 5%;

    }


    #datec2 {
        width: 100%;
        text-align: center;
        margin: 0;
    }

    #datec {
        width: 100%;
        margin: 0;
        text-align: center;

    }


    .dialog {
        margin-right: 90%;
        margin-top: 4%;

    }

    #addArea {
        margin-top: 0;
        height: 100%;
    }


    #deleteButton {
        margin-top: 0;
        width: 20px;
        height: 20px;
        font-size: 12px;
        padding-right: 5px;
        padding-left: 5px;

    }
}

/*Includes basics css, mutualCSS, MainCSS, AddClientWindow, ClientEditorWindowCSS,PaginationCSS*/

table {
    background-color: #FFF;
    color: #024457;
    border: 1px solid #167F92;
    text-align: center;
    width: 100%;
    border-radius: 10px;
    margin: 1em 0;
}

textarea {
    font-size: 13px;
    padding-top: 2%;
    text-align: center;
}

h1 {
    width: 100%;
    text-align: center;
    font-size: 50px;
    margin-top: 50px;
    mso-ansi-font-weight: bold;
    font-weight: normal;
    color: #424242;

}

body {
    padding: 0 0.5em;
    font-family: Arial, sans-serif;
    color: #f2f2f2;
    direction: rtl;
}


tr, td {
    width: 8%;
    text-align: center;
}

label {
    display: block;
    color: #7d7d7d;
}

th {
    color: #FFFFFF;
    width: 8%;
    text-align: center;
    font-size: 16px;
    border: 1px solid #FFFFFF;
    border-bottom: 1px solid black;
    background-color: #136b7e;
    border-bottom: 1px solid black;

    -ms-scrollbar-highlight-color: #656d6e;


}

#addButton {
    text-align: center;
    border: 2px solid #024457;
    border-radius: 100%;
    width: 40px;
    font-size: 30px;
    color: #176a7e;
    min-height: 40px;
    min-width: 40px;
}

td {
    min-width: -webkit-max-content;
    min-width: -moz-max-content;
    min-width: max-content;
    word-wrap: break-word;
    border: 1px solid #D9E4E6;
    padding: 0.5% 0.5% 0.5% 0.5%;

}


#titleStart {
    color: #024457;
    font-size: 50px;
}

.EnterWindow {
    color: #024457;
    direction: ltr;
    height: 25%;
    width: 15%;
    align-self: center;


}

#userNameConteiner {
    display: flex;
    margin-left: 15%;
    margin-top: 5%;
    align-items: center;
    width: 100%;
    justify-content: center;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;

}

#userNameTitle {
    color: #024457;
    font-size: 25px;
    text-align: center;
    width: 25%;
}


#passwordContainer {
    display: flex;
    width: 100%;
    align-items: center;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    justify-content: center;
}

#passwordTitle {
    font-size: 25px;
    width: 25%;
    text-align: center;
    direction: rtl;
}

#submitStart {
    border-radius: 100%;
    height: 100px;
    width: 100px;
    cursor: pointer;
    font-size: 20px;
    margin-top: 1%;
    color: #024457;
    border: 1px solid #024457;
}

#clientsTotal {
    color: black;
}

.popUpDiv {
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: rgba(170, 170, 170, 0.6);
    position: fixed;
}

tbody {
    display: table;
    width: 100%;
}

.deleteMessage {
    left: 50%;
    right: 42%;
    top: 33%;
    bottom: 10%;
    width: 320px;
    height: 190px;
    min-width: 320px;
    min-height: 190px;
    background-color: #FFFFFF;
    border: 1px solid #656d6e;
    color: #176a7e;
    text-align: center;
    position: fixed;
    padding-top: 1%;
    display: flex;
    flex-direction: column;
}

#passDelClient {
    margin-top: 5%;
    margin-bottom: -100%;
    width: 50%;
    margin-right: 30%;
    border: 1px solid lightgray;
    height: 30%;
}

#passDelClientTitle {
    text-align: right;
    margin-top: -6%;
    margin-bottom: 10%;
    margin-right: 4%;
    font-size: 15px;
}

#buttonContainer {
    width: 250px;
    min-width: 250px;
    display: flex;
    margin-right: 30px;
    margin-bottom: 20px;
}

.buttons {
    border-radius: 100%;
    width: 50px;
    height: 50px;
    color: #176a7e;
    border: 2px solid #dae3e3;
    margin-right: 10px;

}

.noCareR {
    background-color: rgba(255, 0, 0, 0.2);

}

.call {
    background-color: rgba(255, 255, 102, 0.5);
}

.noCare {
    color: red;
}

.notAnswerRow {
    background-color: rgba(255, 152, 0, 0.35);
}

.coldLeadRow {
    background-color: rgba(31, 151, 141, 0.2);
}

.dillerClient {
    background-color: rgba(0, 192, 0, 0.28);
}

.lessRelevantRow, .outOfCountry {
    background-color: rgba(216, 211, 216, 0.5);;

}

.meetingRow {
    background-color: rgba(153, 196, 239, 0.5);;

}

.waitingRow {
    background-color: rgba(183, 245, 183, 0.5);
}

.Care {
    color: darkgreen;
}

.Sale {
    color: darkgreen;

}


.exitButton {
    font: 15px arial;
    right: 5px;
    color: #176a7e;
    text-decoration: none;
    border: none;
    background-color: white;
    font-weight: bold;
    top: 5px;
}

.exitButton:after {
    content: 'X'; /* ANSI X letter */
}

#buttonExit {
    padding-bottom: 4.9%;
    margin-right: 97%;
    color: #FFFFFF;
    font-size: 22px;
    width: 30px;
    background-color: rgba(0, 0, 0, 0);
    height: 30px;
}

#editTable {
    height: 100%;
    margin-top: -5%;
    margin-bottom: 1%;
    border: none;

}

#clientEditor {
    border-radius: 10px;
    background-color: #FFFFFF;
    margin-top: 10%;
    margin-right: 15%;
    margin-left: 5% !important;
    width: 70%;
    height: 25%;
}

#commentPop {
    width: 20%;

}

.dillerMark {
    cursor: pointer;
    margin-top: 1px;
    max-width: 60px;
    object-fit: contain;
}

.dealerProfile {
    cursor: pointer;
    object-fit: contain;
    z-index: 3;
    border-radius: 60px;
    max-width: 60px;
    align-self: center;
}

.dealerProfile:hover {
    opacity: 0.8;
}

.popUpDiv {
    align-items: center;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 1000;
    top: 0;
    background-color: rgba(170, 170, 170, 0.6);
    position: fixed;

}

.textEditorClient {
    width: 100%;
    height: 100%;
    max-height: 100px;
    resize: none;
    alignment: center;
    font-size: 15px;
    text-align: center;
    color: #024457;


}

#buttonAdd {
    margin-bottom: 8%;
    margin-top: -0.5%;
    height: 50px;
    width: 50px;
    margin-right: 60%;
    font-size: 14px;
    border: 1px solid #024457;

}

.radioAddClient {
    width: 20px;
    height: 20px;

}

.diller {
    cursor: pointer;
    object-fit: contain;
    border-radius: 60px;
    max-width: 60px;
    align-self: center;
}

.buttonContain {
    display: flex;
    width: 90%;
    padding-left: 5%;
    padding-right: 5%;
    flex-direction: row;

}

.AddCon {
    display: flex;
    margin-right: 10px;
    flex-direction: column;
}

.pagination {
    width: 40%;
    align-items: center;
    display: inline-block;
    color: #02657d;
    list-style: none;
    margin-right: 18%;
    text-decoration: none;
    direction: ltr;
}

.pagination li {
    mso-bidi-font-weight: bold;
    color: black;
    float: left;
    padding: 8px 14px;
    font-weight: bold;


}

.pagination a {
    color: #2a2a2a;
}

.pagination li.active {
    background-color: #167F92;
    color: #FFFFFF;
}

#editPop {
    width: 25%;
}

.normal {
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: -50px;
}

.formPop {
    width: 30%;
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.iconH {
    display: flex;
    flex-direction: column;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.tableCon {
    margin-top: 0;
}

@media only screen and (max-width: 1050px) {
    .tableCon {
        margin-top: 250px;

    }

    .iconH {
        align-items: flex-start;
        margin-right: 15px;
    }

    #buttonContainer {
        width: 95%;
        min-width: 0;
        margin-right: 2.5%;
    }

    .normal {
        margin: 0;
    }

    #userNameTitle {
        color: #024457;
        font-size: 16px;
        min-width: 30%;
    }

    .buttonContain {
        margin-top: 15px;

    }


    .formPop {
        justify-content: flex-start;
        width: 50%;


    }

    .dillerMark, .diller, .dealerProfile {
        max-width: 30px;
    }

    #submitStart {
        height: 70px;
        width: 70px;
        align-self: center;
        font-size: 12px;
    }

    #titleStart {
        font-size: 30px;
    }

    #passwordTitle {
        font-size: 16px;
        width: 30%;
        direction: rtl;
    }

    #userNameConteiner, #passwordContainer {
        margin-left: 0;
        width: 100%;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        margin-right: 0;
        min-height: 75px;
        margin-top: 0;

    }

    .radioAddClient {
        width: 15px;
        height: 15px;

    }

    .deleteMessage {
        width: 90%;
        right: 5%;
        left: 5%;
        min-width: 200px;
    }

    .EnterWindow {
        height: 16px;
        width: 40%;
    }

    .textEditorClient {
        width: 95%;
        font-size: 13px;


    }

    .selAdd {
        width: 100px;
        max-width: 60%;
    }

    .greenTitle {
        font-size: 20px;
        margin-top: 0;
    }

    #clientEditor {
        margin-right: 2.5%;
        margin-left: 2.5% !important;
        width: 95%;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        margin-top: 50%;
    }

    .pagination {
        width: 100%;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        font-size: 12px;
    }

    #buttonAdd {
        margin-bottom: 15px;
        margin-top: 10px;
        height: 40px;
        margin-right: 48%;
        width: 40px;
        font-size: 12px;
        border: 1px solid #024457;

    }

    #clients td, #clients tr, #clients th {
        min-width: 60px;

    }

    th, tr, td {

        width: -webkit-fit-content;

        width: -moz-fit-content;

        width: fit-content;
        font-size: 13px;
        text-align: center;
        padding-right: 5px;
        padding-left: 5px;
        min-width: 120px;
    }

    td {
        height: 50px;
    }

    #editTable {
        width: 98%;
        margin-top: 0;
        margin-right: 1%;
        display: block;
        margin-bottom: 0;

    }

    #addButton {
        text-align: center;
        padding: 0;
        display: flex;
        justify-content: center;
        width: 40px;
        height: 40px;
        font-size: unset;
        color: #176a7e;
        min-height: 0;
        background-color: #e5e5e5;
        min-width: 0;
    }

    body {
        padding: 0 0;
        -ms-scroll-snap-type: none;
            scroll-snap-type: none

    }

    #buttonExit {
        margin-right: 90%;
        color: #176a7e;

    }

    tbody {
        display: inline-block;
    }

}

/*/////////////////////////////*/





.graphCell {
    width: 10%;
}

.graphTable {
    margin-top: 15%;
    width: 100%;
    height: 5%;
}

.popUpDivG {
    align-items: center;
    width: 100%;
    min-height: 20%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    left: 0;
    top: 0;
    z-index: 0;
    position: absolute;

}

.graphTitle {
    font-size: 30px;
    font-family: Arial, Arial, sans-serif;
    color: #02657d;
    width: 100%;
    margin: 1px;
    text-align: center;
}

.GpoUpChild {
    direction: rtl;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    justify-content: flex-end;
    margin-top: 50px;
    margin-left: 100px;

}

.graphContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    width: 500px;
}

@media only screen and (max-width: 1000px) {
    .graphContainer {
        margin-top: 50px;
        width: 100%;


    }

    .GpoUpChild {
        justify-content: center;
    }
}

.inputContainer {

    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    height: 30px;
    max-width: 400px;
}

.dealerP {
    width: 98%;
}

.dealerPop {
    display: block;
    overflow: scroll;
    flex-direction: column;
    height: 100%;

}

.tableCell {
    width: 22%;
    min-width: 100px;
}

.tableDiller {
    background-color: #0f566529;
    height: 90%;
    font-size: 20px !important;
}

.imageDillers {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.buttonDiller {
    border-radius: 100%;
    width: 70px;
    height: 70px;
    color: #176a7e;
    border: 1px solid #176a7e;
    margin-bottom: 8%;
    font-size: 18px;
    background-color: #eeeeee;
    font-family: Arial, sans-serif;


}

.editContainer {
    display: flex;
    justify-content: center;
}

.editIcon {
    cursor: pointer;
    width: 26px;
    height: 26px;
    padding-right: 7px;
}

.editIcon:hover {
    opacity: 0.8;
}

.DpoUpChild {
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    padding: 0 2em;
    width: 50%;
    height: 50%;
    background-color: white;
    margin-right: 25%;
    margin-top: 10%;
    border: 1px solid #167F92;
}

.formDiller {
    margin-top: 20px;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    width: 100%;
    align-items: center;
    font-family: Arial, sans-serif;
    height: 40%;
}

.textForm {
    color: #024457;
    font-size: 20px;

}

.textDiller {
    color: #024457;
    font-size: 18px;
}

.preview_container {

}

@media only screen and (max-width: 1050px) {
    .inputContainer {

        width: 100%;
    }

    .dealerPop {
        overflow-y: scroll;
        flex-direction: column;
        height: 100%;

    }

    .dealerP {
        display: block
    }

    .textForm, .textDiller {
        font-size: 17px;

    }

    .textDiller {
        max-width: 150px;
        height: 15px;
    }

    .buttonDiller {
        width: 55px;
        height: 55px;
        margin-bottom: 8%;
        font-size: 15px;


    }

    .formDiller {
        margin-top: 10px;
        height: 70%;
        max-width: 300px;
        align-self: center;
    }

    .DpoUpChild {
        padding-right: 5%;
        padding-left: 5%;
        width: 80%;
        margin-right: 5%;
        margin-left: 5%;
        margin-top: 30%;

    }
}

.DealerHolder {
    height: 300px;
    background-color: white;
    border: 1px solid black;
    border-radius: 10px;
    width: 300px;
    right: 100px;
    z-index: 1000;
    top: 50px;
    display: flex;
    flex-direction: column;
    position: absolute;
}

.titleProfile {
    text-align: center;
    font-size: 2em;
    margin-right: 5px;
    color: #024457;
    margin-top: 2px;
}

.textProfile {
    margin-top: 14px;
    color: #024457;
    margin-right: 5px;
    font-weight: lighter;
    text-align: center;
    font-size: 1.1em;
}

.editPro {
    width: 20px;
    cursor: pointer;
    text-align: center;
    height: 20px;
}

.editPro:hover {
    opacity: 0.8;
}

.passContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    width: 80%;
    height: 40%;
}

.passInvalid {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    width: 80%;
    height: 40%;
    opacity: 0.2;
}

.inputProfile {
    margin-top: 7px;
    width: 100%;
    height: 18px;

}

.profileButton {
    margin-top: 7px;
    width: 90%;
    align-self: center;
    height: 18px;

}

.inputMail {
    width: 90%;
    align-self: center;
    margin-top: 10px;
    text-align: center;
}

.deleteButton {
    border: 1px solid #024457;
    border-radius: 100%;
    width: 26px;
    height: 26px;
    font-size: 1em;
    margin-right: 90%;

    margin-top: 5px;
    cursor: pointer;
    text-align: center;
}

.deleteButton:hover {
    opacity: 0.8;
}
@media only screen and (max-width: 1250px) {
    .DealerProfileContainer{
        width: 100vw;
        background-color: rgba(255,255,255,0.5);
        z-index: 1000;
        height: 60vh;
        display: flex;
        justify-content: center;
        right: 0;
        position: absolute;
    }
    .DealerHolder {
        right: unset;
    }

}
.programContainer {
    margin-top: 100px;
    display: flex;
    direction:ltr;
    flex-direction: column;
}

.title {
    font-size: 40px;
    width: 100%;
    text-align: center;
    color: #0f5665;
    margin-top: 40px;
    margin-bottom: 40px;
}

.SearchContainer {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
}

.searchP {
    background-color: white;
    border: none;
    width: 100%;

}

.tableP {
    border: none;
}

.inputP {
    width: 96%;
    margin-bottom: 10px;
    background-color: white;
    direction: ltr;
}

.noSearch {
    vertical-align: bottom;
    background-color: white;
}

.noSearchInput {
    background-color: #136b7e;
    height: 30px;
    width: 100%;

}
#amountSearch{
}
.amount{
    font-size: 20px;
    width: 100%;
    direction: ltr;
    margin-left: 10px;
    align-self: end;
    color: #024457;
}
.load{
    font-size: 20px;
    color: #024457;
}
.added{

    width: 96%;
    margin-bottom: 10px;
    direction: ltr;
    color: #D9E4E6;
    background-color: rgba(35, 117, 48, 0.7);
}
.dataR{
    display: flex;
    justify-content: center;
    background-color: rgba(255,255,255,0.9);
    position: absolute;
    flex-direction: row;
    width:100vw;
    height:100vh;

}
.searchIP {
    background-color: #136b7e;
    display: flex;
    font-size: 15px;
    flex-direction: column;
    height: 40px;
    justify-content: center;

}
